<script setup>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";
import { computed, ref } from "vue";
import { SelectorIcon } from "@heroicons/vue/outline";
import {
  useElementBounding,
  useElementVisibility,
  useWindowSize,
} from "@vueuse/core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  options: {
    type: [Object],
    required: true,
  },
  disabled: {
    type: [Boolean, Number],
    required: false,
    default: false,
  },
  disabledOptions: {
    type: [Array],
    required: false,
    default: () => [],
  },
  noBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  showXButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: "none",
  },
  infoCircleColor: {
    type: String,
    required: false,
    default: "text-gray-500",
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  noInitialSelection: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const tmpValue = computed({
  get() {
    return props.modelValue || props.modelValue === 0 || props.modelValue === ""
      ? props.options.find((item) => item.value === props.modelValue)
      : props.noInitialSelection
      ? null
      : props.options[0];
  },
  set(evt) {
    emit("update:modelValue", evt.value);
  },
});

const inputFocus = ref(null);
const functionRef = (el) => {
  inputFocus.value = el;
};

const { right, bottom, left, top } = useElementBounding(inputFocus);
const targetIsVisible = useElementVisibility(inputFocus);
const { height } = useWindowSize();

const styleObject = computed(() => {
  let bottomTmp = {
    maxHeight: height.value - bottom.value - 50 + "px",
    top: bottom.value + 4 + "px",
    width: right.value - left.value + "px",
    left: left.value + "px",
  };
  let topTmp = {
    maxHeight: top.value - 50 + "px",
    top: top.value - 4 + "px",
    width: right.value - left.value + "px",
    left: left.value + "px",
    transform: "translateY(-100%)",
    marginBottom: 4 + "px",
  };
  return height.value - bottom.value > 200 ? bottomTmp : topTmp;
});

const checkIfPlaceholder = computed(() => {
  if (!!props.modelValue && !!tmpValue.value) return true;
  if (props.modelValue === 0 && tmpValue.value) return true;
  return props.modelValue === "" && tmpValue.value;
});
</script>

<template>
  <Listbox v-model="tmpValue" as="div" :disabled="!!disabled">
    <ListboxLabel
      class="text-xs font-medium text-gray-500 mb-1 flex gap-0.5 items-center"
    >
      <span>
        {{ label }}
      </span>
      <div v-if="tooltipText">
        <BaseTooltipNew
          :tooltipText="tooltipText"
          :tooltipColor="infoCircleColor"
        />
      </div>
    </ListboxLabel>

    <div class="relative">
      <ListboxButton
        :ref="functionRef"
        class="relative w-full cursor-pointer bg-white text-left focus:outline-none disabled:cursor-default"
        :class="[
          noBorder
            ? 'border-transparent text-xs py-2 px-3'
            : 'border rounded-md border-gray-300 shadow-sm text-sm py-1 px-3 disabled:bg-gray-200',
        ]"
      >
        <span
          class="block truncate h-5 text-gray-500"
          :class="[
            checkIfPlaceholder ? 'text-gray-900 text-sm' : 'text-gray-500',
          ]"
        >
          {{ checkIfPlaceholder ? tmpValue.name : placeholder }}
        </span>

        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <span
        v-if="showXButton && !!tmpValue"
        class="absolute inset-y-0 right-0 flex items-center mr-8 cursor-pointer"
        @click="emit('update:modelValue', '')"
      >
        <font-awesome-icon icon="fa-solid fa-x" class="h-3 w-3 text-gray-400" />
      </span>
    </div>

    <Teleport to="body">
      <ListboxOptions
        v-if="targetIsVisible"
        class="z-[999999] absolute border overflow-y-auto overscroll-x-hidden rounded-md bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus-visible:border-indigo-500 sm:text-sm"
        :style="styleObject"
      >
        <ListboxOption
          v-if="!options || options.length === 0"
          :disabled="true"
          as="template"
        >
          <li
            class="relative cursor-pointer select-none py-2 px-2 text-gray-900 font-normal"
          >
            <span class="block truncate"> No options </span>
          </li>
        </ListboxOption>

        <ListboxOption
          v-else
          v-slot="{ active, selected, disabled }"
          v-for="option in options"
          :key="option"
          :value="option"
          :disabled="disabledOptions.includes(option.value)"
          as="template"
        >
          <li
            class="rounded relative cursor-pointer list-none py-2 px-2"
            :class="[
              disabled ? 'bg-gray-300' : '',
              active ? 'bg-main1-dark text-white' : 'text-gray-900',
              selected ? 'font-medium text-white bg-main1' : 'font-normal',
            ]"
          >
            <span class="block truncate">
              {{ option.name }}
            </span>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </Teleport>
  </Listbox>
</template>
