<script setup>
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { useElementBounding, useWindowSize } from "@vueuse/core";
import useFoldersStore from "@/stores/folders.js";
import { SelectorIcon } from "@heroicons/vue/outline";

const foldersStore = useFoldersStore();
const { type, folderList } = storeToRefs(foldersStore);

const props = defineProps({
  modelValue: {
    type: [Number, String, null],
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  rootName: {
    type: String,
    required: false,
    default: "Main folder",
  },
  id: {
    type: String,
    required: true,
  },
  disabled: {
    type: [Boolean, Number],
    required: false,
    default: false,
  },
  noBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  requestType: {
    type: String,
    required: false,
    default: "media",
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  tooltipPlacement: {
    type: String,
    required: false,
    default: "top",
  },
  infoCircleColor: {
    type: String,
    required: false,
    default: "text-gray-500",
  },
});

const emit = defineEmits(["update:modelValue"]);

onMounted(() => {
  if (!folderList.value || props.requestType !== type.value) {
    foldersStore.type = props.requestType;
    foldersStore.getFolderList();
  }
});

const rootFolder = computed(() => {
  const folderTmp = folderList.value
    ? [{ ...folderList.value[0], name: props.rootName }]
    : [
        {
          name: props.rootName,
          id: 0,
          children: [],
        },
      ];

  return createFolderTree(folderTmp);
});

const selectedFolder = computed({
  get() {
    let val = parseInt(props.modelValue);
    if (props.modelValue === "") val = 0;
    if (!props.modelValue) val = 0;
    if (props.modelValue === undefined) val = 0;
    return rootFolder.value.find((item) => item.id === val);
  },
  set(evt) {
    emit("update:modelValue", evt.id);
  },
});

const createFolderTree = (
  foldersValue,
  foldersTmp = [],
  parent_id = 0,
  space = ""
) => {
  for (let folder in foldersValue) {
    foldersTmp.push({
      id: foldersValue[folder]["id"],
      space: space,
      name: foldersValue[folder]["name"],
      pid: parent_id,
    });

    if (foldersValue[folder].children) {
      if (
        typeof foldersValue[folder].children !== "undefined" &&
        foldersValue[folder].children.length > 0
      ) {
        const spaceTmp = space + "\xa0\xa0\xa0\xa0";
        createFolderTree(
          foldersValue[folder].children,
          foldersTmp,
          foldersValue[folder]["id"],
          spaceTmp
        );
      }
    }
  }
  return foldersTmp;
};

const inputFocus = ref(null);
const functionRef = (el) => {
  inputFocus.value = el;
};

const { right, bottom, left, top } = useElementBounding(inputFocus);
const { height } = useWindowSize();

const styleObject = computed(() => {
  let bottomTmp = {
    maxHeight: height.value - bottom.value - 50 + "px",
    top: bottom.value + 4 + "px",
    width: right.value - left.value + "px",
    left: left.value + "px",
  };
  let topTmp = {
    maxHeight: top.value - 50 + "px",
    top: top.value - 4 + "px",
    width: right.value - left.value + "px",
    left: left.value + "px",
    transform: "translateY(-100%)",
    marginBottom: 4 + "px",
  };
  return height.value - bottom.value > 200 ? bottomTmp : topTmp;
});
</script>

<template>
  <div v-if="selectedFolder">
    <div class="flex gap-0.5 items-center">
      <label :for="id" class="block text-xs font-medium text-gray-500">
        {{ label }}
      </label>

      <BaseTooltipNew
        v-if="tooltipText"
        :tooltipText="tooltipText"
        :placement="tooltipPlacement"
        :tooltipColor="infoCircleColor"
      />
    </div>

    <Listbox v-model="selectedFolder" as="div">
      <div class="relative mt-1">
        <ListboxButton
          :ref="functionRef"
          :disabled="disabled"
          class="relative w-full cursor-pointer bg-white text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 disabled:bg-gray-200 disabled:cursor-default"
          :class="[
            noBorder
              ? 'border-b-gray-300 border-t-transparent border-x-transparent border-b focus:border-transparent focus:border-x-transparent focus:border-b-gray-300 text-xs py-2 px-3 '
              : 'border rounded-md border-gray-300 shadow-sm sm:text-sm py-1 px-3 ',
          ]"
        >
          <span class="block truncate">{{ selectedFolder["name"] }}</span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <Teleport to="body">
            <ListboxOptions
              class="z-[999999] absolute border overflow-y-auto overscroll-x-hidden rounded-md bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus-visible:border-indigo-500 sm:text-sm"
              :style="styleObject"
            >
              <ListboxOption
                v-slot="{ active }"
                v-for="folder in rootFolder"
                :key="folder.id"
                :value="folder"
                as="template"
              >
                <li
                  class="relative cursor-pointer select-none py-2 px-2 rounded"
                  :class="[
                    active ? 'bg-main1-dark text-white' : 'text-gray-900',
                    selectedFolder['name'] === folder.name
                      ? 'font-medium text-white bg-main1'
                      : 'font-normal',
                  ]"
                >
                  <span class="block truncate"
                    >{{ folder.space }} {{ folder.name }}</span
                  >
                </li>
              </ListboxOption>
            </ListboxOptions>
          </Teleport>
        </transition>
      </div>
    </Listbox>
  </div>
</template>
