import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:type?/:id?",
    name: "media",
    component: () => import("../views/Media/AllMedia.vue"),
    meta: {
      navbar: true,
      title: "Media",
      guard: true,
    },
  },
  {
    path: "/add-media/",
    name: "add-media",
    component: () => import("../views/Media/AddMedia.vue"),
    meta: {
      navbar: true,
      title: "Add media",
      guard: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Auth/Backscreen/LoginAuthNew.vue"),
    meta: {
      navbar: false,
      title: "Login",
      guard: false,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: () => import("../views/Auth/Backscreen/ForgotPasswordNew.vue"),
    meta: {
      navbar: false,
      title: "Forgot password",
      guard: false,
    },
  },
  {
    path: "/reset-password",
    name: "reset_password",
    component: () => import("../views/Auth/Backscreen/ResetPasswordNew.vue"),
    meta: {
      navbar: false,
      title: "Reset password",
      guard: false,
    },
  },
  {
    path: "/set-password",
    name: "set_password",
    component: () => import("../views/Auth/Backscreen/SetPasswordNew.vue"),
    meta: {
      navbar: false,
      title: "Set password",
      guard: false,
    },
  },
  {
    path: "/verify-email/:hash?",
    name: "verify-email",
    component: () =>
      import("../views/Auth/Backscreen/Onboarding/VerifyEmailNew.vue"),
    meta: {
      navbar: false,
      title: "Verify email",
      guard: false,
    },
  },
  // {
  {
    path: "/account-created",
    name: "account-created",
    component: () =>
      import("../views/Auth/Backscreen/Onboarding/AccountCreatedNew.vue"),
    meta: {
      navbar: false,
      title: "Account created",
      guard: false,
    },
  },
  {
    path: "/delete-client/:hash?",
    name: "delete-client",
    component: () => import("../views/Admin/ClientDelete.vue"),
    meta: {
      navbar: false,
      title: "Delete client",
      guard: false,
    },
  },
  {
    path: "/delete-client/:hash?",
    name: "delete-client",
    component: () => import("../views/Admin/ClientDelete.vue"),
    meta: {
      navbar: false,
      title: "Delete client",
      guard: false,
    },
  },
  {
    path: "/pre-payment",
    name: "pre-payment",
    component: () => import("../views/Auth/Backscreen/PayBeforeLoginNew.vue"),
    meta: {
      navbar: false,
      title: "Prepayment",
      guard: false,
    },
  },
  {
    path: "/user/settings",
    name: "user.settings",
    component: () => import("../views/Admin/ProfileView.vue"),
    meta: {
      navbar: true,
      title: "User settings",
      guard: true,
    },
  },
  {
    path: "/delete-user/:hash?",
    name: "delete-user",
    component: () => import("../views/Auth/Backscreen/DeleteUserNew.vue"),
    meta: {
      navbar: false,
      title: "Delete user",
      guard: false,
    },
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () => import("../views/Admin/AccountsView.vue"),
    meta: {
      navbar: true,
      title: "Accounts",
      guard: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/Admin/SettingsView.vue"),
    meta: {
      navbar: true,
      title: "Settings",
      guard: true,
    },
  },
  {
    path: "/folders",
    name: "folders",
    component: () => import("../views/Folders/FoldersView.vue"),
    meta: {
      navbar: true,
      title: "Folders",
      guard: true,
    },
  },
  {
    path: "/playback/:section/:type?/:id?",
    name: "playback",
    component: () => import("../views/Playback/PlaybackView.vue"),
    meta: {
      navbar: true,
      title: "Playback",
      guard: true,
    },
  },
  {
    path: "/billing",
    name: "billing",
    component: () => import("../views/Admin/Billing/BillingView.vue"),
    meta: {
      navbar: true,
      title: "Billing",
      guard: true,
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/Admin/Legal.vue"),
    meta: {
      navbar: true,
      title: "Terms and Policies",
      guard: true,
    },
  },
  {
    path: "/payment/:status?/:hash?",
    name: "payment",
    component: () => import("../views/Admin/Billing/VerifyPayment.vue"),
    meta: {
      navbar: false,
      title: "Payment",
      guard: true,
    },
  },
  {
    path: "/card/:status?/:hash?",
    name: "card",
    component: () => import("../views/Admin/Billing/VerifyCard.vue"),
    meta: {
      navbar: false,
      title: "Card",
      guard: true,
    },
  },
  // views/Groups New
  {
    path: "/groups/:type?/:id?",
    name: "groups",
    component: () => import("../views/Groups/GroupsView.vue"),
    meta: {
      navbar: true,
      title: "Groups",
      guard: true,
    },
  },
  {
    path: "/tickets/:type?/:id?",
    name: "tickets",
    component: () => import("../views/Tickets/Tickets.vue"),
    meta: {
      navbar: true,
      title: "Tickets",
      guard: true,
    },
  },
  {
    path: "/tickets/create",
    name: "create ticket",
    component: () => import("../views/Tickets/CreateTicket.vue"),
    meta: {
      navbar: true,
      title: "Create ticket",
      guard: true,
    },
  },
  {
    path: "/channels/:section/:type?/:id?",
    name: "channels",
    component: () => import("../views/Channels/ChannelsView.vue"),
    meta: {
      navbar: true,
      title: "Channels",
      guard: true,
    },
  },
  {
    path: "/vod2live/:section/:type?/:id?",
    name: "vod2live",
    component: () => import("../views/VOD2Live/VOD2Live.vue"),
    meta: {
      navbar: true,
      title: "VOD2Live",
      guard: true,
    },
  },
  {
    path: "/analytics",
    name: "analytics",
    component: () => import("../views/Analytics/Analytics.vue"),
    meta: {
      navbar: true,
      title: "Analytics",
      guard: true,
    },
  },
  {
    path: "/demo/:section",
    name: "demo",
    component: () => import("../views/Demo/DemoView.vue"),
    meta: {
      navbar: true,
      title: "Demo",
      guard: true,
    },
  },
  {
    path: "/ott/:section/:type?/:id?",
    name: "ott",
    component: () => import("../views/OTT/OTTView.vue"),
    meta: {
      navbar: true,
      title: "OTT",
      guard: true,
    },
  },
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
