<script setup>
import { computed } from "vue";

const props = defineProps({
  label: {
    type: String,
    required: false,
  },
  modelValue: {
    type: [Number, String, Boolean, null],
    required: true,
    default: 0,
  },
  id: {
    type: String,
    required: true,
    default: "checkbox",
  },
  big: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: [Boolean, Number],
    required: false,
    default: false,
  },
  round: {
    type: Boolean,
    required: false,
    default: false,
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  tooltipLink: {
    type: String,
    required: false,
    default: "",
  },
  tooltipColor: {
    type: String,
    required: false,
    default: "",
  },
  tooltipPlacement: {
    type: String,
    required: false,
    default: "top",
  },
  tooltipSize: {
    type: String,
    required: false,
    default: "",
  },
  labelStyle: {
    type: String,
    required: false,
    default: "",
  },
  checkboxColor: {
    type: String,
    required: false,
    default: "",
  },
  showDefaultTooltipIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const tmpValue = computed({
  get() {
    if (typeof props.modelValue === "boolean") return props.modelValue;

    if (props.modelValue === "true") return true;
    if (props.modelValue === "false") return false;

    return props.modelValue !== 0;
  },
  set(evt) {
    if (typeof props.modelValue === "boolean") {
      emit("update:modelValue", !props.modelValue);
    } else if (typeof props.modelValue === "string") {
      if (evt === true) emit("update:modelValue", "true");
      else emit("update:modelValue", "");
    } else {
      if (evt === true) emit("update:modelValue", 1);
      else emit("update:modelValue", 0);
    }
  },
});
</script>

<template>
  <div class="flex items-center h-5">
    <input
      v-model="tmpValue"
      :id="id"
      :name="id"
      type="checkbox"
      :disabled="disabled"
      class="focus:ring-0 rounded disabled:bg-gray-200"
      :class="[
        {
          'h-5 w-5': big,
          'rounded-full': round,
          'hover:text-gray-200': disabled,
        },
        disabled ? 'cursor-default' : 'cursor-pointer',
        checkboxColor ? checkboxColor : 'text-main1 border-gray-300',
      ]"
    />

    <label
      :for="id"
      class="ml-3"
      :class="[
        disabled ? 'cursor-default' : 'cursor-pointer',
        labelStyle ? labelStyle : 'text-bs-black font-medium text-xs',
      ]"
    >
      {{ label }}
    </label>

    <BaseTooltipNew
      v-if="tooltipText"
      :tooltip-text="tooltipText"
      :tooltip-color="tooltipColor"
      :tooltip-size="tooltipSize"
      :placement="tooltipPlacement"
      :show-default-icon="showDefaultTooltipIcon"
    />
  </div>
</template>
