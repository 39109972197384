<script setup>
import { computed, ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";
import { SelectorIcon } from "@heroicons/vue/outline";
import {
  useElementBounding,
  useElementVisibility,
  useWindowSize,
} from "@vueuse/core";

const props = defineProps({
  value: {
    type: [String, Number, Object, null],
    required: false,
    default: "",
  },
  label: {
    type: String,
    required: false,
  },
  options: {
    type: [Object, Array],
    required: true,
  },
  trackBy: {
    type: [String],
    required: false,
    default: "name",
  },
  disabled: {
    type: [Boolean, Number],
    required: false,
    default: false,
  },
  defaultSelectValue: {
    type: String,
    required: false,
    default: "none",
  },
  disabledOptions: {
    type: [Array],
    required: false,
    default: [],
  },
  trackDisabledKey: {
    type: String,
    required: false,
    default: "value",
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  tooltipPlacement: {
    type: String,
    required: false,
    default: "top",
  },
});

const emit = defineEmits(["onUpdate:value"]);

const tmpValue = computed({
  get() {
    if (!props.value) {
      return "";
    }
    return props.value;
  },
  set(evt) {
    emit("onUpdate:value", evt);
  },
});

const inputFocus = ref(null);

const functionRef = (el) => {
  inputFocus.value = el;
};

const { right, bottom, left, top } = useElementBounding(inputFocus);
const targetIsVisible = useElementVisibility(inputFocus);
const { height } = useWindowSize();

const styleObject = computed(() => {
  let bottomTmp = {
    maxHeight: height.value - bottom.value - 50 + "px",
    top: bottom.value + 4 + "px",
    width: right.value - left.value + "px",
    left: left.value + "px",
  };
  let topTmp = {
    maxHeight: top.value - 50 + "px",
    top: top.value - 4 + "px",
    width: right.value - left.value + "px",
    left: left.value + "px",
    transform: "translateY(-100%)",
    marginBottom: 4 + "px",
  };
  return height.value - bottom.value > 200 ? bottomTmp : topTmp;
});
</script>

<template>
  <Listbox
    as="div"
    class="w-full"
    :disabled="disabled"
    v-slot="{ open }"
    v-model="tmpValue"
  >
    <ListboxLabel
      class="text-xs font-medium text-gray-500 mb-1 flex items-center"
    >
      {{ label }}
      <div v-if="tooltipText">
        <BaseTooltipNew
          :tooltipText="tooltipText"
          :placement="tooltipPlacement"
        />
      </div>
    </ListboxLabel>

    <ListboxButton
      :ref="functionRef"
      :key="open"
      class="w-full relative cursor-pointer disabled:cursor-default rounded-lg bg-white py-1 px-3 text-left border border-gray-300 shadow-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm disabled:bg-gray-200"
    >
      <span v-if="tmpValue" class="block truncate">
        {{ tmpValue[trackBy] }}
      </span>
      <span v-else class="block truncate">
        {{ defaultSelectValue }}
      </span>
      <span
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
      >
        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </span>
    </ListboxButton>

    <transition
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <Teleport to="body">
        <ListboxOptions
          v-if="targetIsVisible"
          class="z-[999999] absolute border overflow-y-auto overscroll-x-hidden rounded bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
          :style="styleObject"
        >
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="option in options"
            :key="option"
            :value="option"
            :disabled="disabledOptions.includes(option[trackDisabledKey])"
            as="template"
          >
            <li
              class="rounded relative cursor-pointer select-none py-2 px-2"
              :class="[
                disabledOptions.includes(option[trackDisabledKey])
                  ? 'bg-gray-100'
                  : '',
                active ? 'bg-main1-dark text-white' : 'text-gray-900',
                selected ? 'font-medium text-white bg-main1' : 'font-normal',
              ]"
            >
              <span class="block truncate">{{ option[trackBy] }}</span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </Teleport>
    </transition>
  </Listbox>
</template>
