import { defineStore } from "pinia";
import { app } from "@/main";
import axiosClient from "@/axiosClient";
import { filterDuplicateValues } from "@/composables/filterDuplicateValues";

const URL = import.meta.env.VITE_API_URL;

export const useGeneralStore = defineStore({
  id: "useGeneralStore",
  state: () => ({
    countryListLoading: false,
    countryList: null,
    languageListLoading: false,
    languageList: null,
  }),

  actions: {
    getCountryList() {
      this.countryListLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/general/getcountrylist")
          .then((response) => {
            if (response.data.code === 0) {
              this.countryList = response.data.data;
              this.countryListLoading = false;
            }
            resolve(response);
          })
          .catch((error) => {
            this.countryListLoading = false;
            reject(error);
          });
      });
    },
    getLanguageList() {
      this.languageListLoading = true;

      return new Promise((resolve, reject) => {
        axiosClient
          .get(URL + "/general/getlanguagelist")
          .then((response) => {
            if (response.data.code === 0) {
              this.languageList = filterDuplicateValues(response.data.data);
              this.languageListLoading = false;
            }
            resolve(response);
          })
          .catch((error) => {
            this.languageListLoading = false;

            reject(error);
          });
      });
    },
  },
});
