<script setup>
import useTooltip from "@/composables/global/tooltip";

const props = defineProps({
  theme: {
    type: String,
    default: "dark",
  },
  type: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  iconClass: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  actionInProgress: {
    type: Boolean,
    required: false,
    default: false,
  },
  tooltipText: {
    type: String,
    required: false,
    default: "",
  },
  tooltipPlacement: {
    type: String,
    required: false,
    default: "top",
  },
});

const { showTooltip, reference, floating, floatingStyles } = useTooltip(
  props.tooltipPlacement
);

const emit = defineEmits(["click"]);

const clickFn = () => {
  emit("click");
};
</script>

<template>
  <div class="w-fit" :class="{ 'h-4': theme === 'icon' }">
    <button
      type="button"
      ref="reference"
      :disabled="disabled || actionInProgress"
      :class="[
        'w-full items-center m-0',
        'text-base font-medium leading-6',
        'transition duration-250 ease-in-out',
        'focus:outline-none focus:ring-0',
        'disabled:opacity-50',
        { 'pr-4': theme !== 'icon' },
        [theme === 's' ? 'py-[3px]' : theme === 'icon' ? 'p-0' : 'py-1'],

        [theme === 'icon' ? 'p-0' : icon ? 'pl-2' : 'pl-4'],
        {
          'text-white bg-main1 hover:bg-main1-dark py-2 px-4 rounded-lg':
            theme === 'dark',
        },
        {
          'text-white bg-main1 hover:bg-main1-dark rounded-2xl': theme === 'p',
        },
        {
          'text-black bg-transparent hover:bg-bs-light-gray border border-main1 rounded-2xl disabled:bg-bs-light-gray':
            theme === 's' && !type,
        },
        {
          'text-black bg-transparent hover:text-bs-red hover:bg-bs-light-gray border border-main1 hover:border-bs-red rounded-2xl disabled:bg-bs-light-gray':
            theme === 's' && type === 'd',
        },
        {
          'text-main1 bg-transparent rounded-2xl': theme === 't',
        },
        {
          'p-0 text-black hover:text-bs-black hover:scale-105':
            theme === 'icon' && !type,
        },
        {
          'p-0 text-black hover:text-bs-red hover:scale-105':
            theme === 'icon' && type === 'd',
        },
      ]"
      @mouseover="tooltipText ? (showTooltip = true) : (showTooltip = false)"
      @mouseleave="showTooltip = false"
      @click="clickFn()"
    >
      <span
        v-if="actionInProgress"
        class="animate-spin w-4 h-4 border-4 border-b-transparent rounded-full text-center"
        role="status"
      />

      <span v-else class="flex gap-2 items-center justify-center">
        <font-awesome-icon
          v-if="icon"
          :icon="icon"
          :class="iconClass ? iconClass : 'w-4 h-4'"
        />

        <slot />
      </span>
    </button>

    <div
      v-show="showTooltip"
      ref="floating"
      :style="floatingStyles"
      class="bg-gray-400 p-2 rounded z-[9999] text-xs text-white font-medium text-wrap"
    >
      {{ tooltipText }}
    </div>
  </div>
</template>
